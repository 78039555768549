
import { defineComponent, reactive, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import Error from "@/components/base/Error.vue";
import UserService from "@/core/services/UserService";
import { useToast } from "vue-toast-notification";

export default defineComponent({
  name: "Forgot",
  components: {
    Error,
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const router = useRouter();
    const loginSubmitting = ref(false);
    const signError = ref({isError: false, message: ''})
    const state = reactive({
      email: '',
    })
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });
    //Form submit function
    const onSubmitLogin = (values) => {
      loginSubmitting.value = true
      UserService.forgotPassword(values).then(() => {
        useToast().success('We sent you an email to reset password', {position: 'top-right'})
        router.push({
          name: 'sign-in'
        })
      }).catch(error => {
        signError.value = {isError: true, message: error.data.message}
      }).finally(() => {
        loginSubmitting.value = false
      })
    };


    return {
      onSubmitLogin,
      loginSubmitting,
      login,
      state,
      signError,
    };
  },
});
